<template>
  <v-layout style="position: relative;" column>
    <v-row>
      <v-col cols="2">
        <div class=" mt-5" style="position: sticky;top: 20px; height: 90vh;overflow-y: auto; ">
          <div style="position:sticky;top: 0; background-color:#000621!important;z-index:100">
            <div
              style="
                flex: none;
                height: 60px;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid rgba(93, 101, 136, 0.5);
              "
            >
              <v-layout align-center class="fill-height px-5">
                <v-btn class="mr-2" dark fab small @click="$router.push('/admin/cms')">
                  <v-icon>icon-arrow_left</v-icon>
                </v-btn>
                <h3 class="text-uppercase">STRUCTURE</h3>
              </v-layout>
            </div>
            <div class="ma-2" style="display: flex;justify-content: space-around;">
              <v-btn color="primary" rounded @click="updateMenus()" :loading="loading">
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn color="primary" rounded @click="undo()">
                <v-icon left>mdi-arrow-left-top</v-icon>
                Reset
              </v-btn>
            </div>
          </div>
          <v-expansion-panels class="custom-expansion" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="custom-card-bg-1 text-truncate mt-1">
                <div class="text-truncate" style="flex: none; width: 160px; font-size: 14px">
                  Domains
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background: transparent">
                <v-text-field v-model="domainSearch" label="Search" single-line hide-details></v-text-field>
                <v-list-item
                  v-for="(item, index) in filteredDomains"
                  :key="item.code"
                  draggable="true"
                  @dragstart="dragStart({ children: filteredDomains, type: 'itemList' }, index, true)"
                  class="pr-0 pl-1 my-1 position-relative custom-card-bg-1"
                  style="cursor:grab;"
                >
                  <v-list-item-content>
                    <v-list-item-title @click="domain = item.code" class="text-truncate" style="font-size: 14px">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="custom-expansion" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="custom-card-bg-1 text-truncate mt-1">
                <div class="text-truncate" style="flex: none; width: 160px; font-size: 14px">
                  Groups
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background: transparent">
                <v-text-field v-model="groupSearch" label="Search" single-line hide-details></v-text-field>
                <v-list-item
                  v-for="(item, index) in filteredGroups"
                  :key="item.code"
                  draggable="true"
                  @dragstart="dragStart({ children: filteredGroups, type: 'itemList' }, index, true)"
                  class="pr-0 pl-1 my-1 position-relative custom-card-bg-1"
                  style="cursor:grab;"
                >
                  <v-list-item-content>
                    <v-list-item-title @click="group = item.code" class="text-truncate" style="font-size: 14px">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="custom-expansion" focusable>
            <v-expansion-panel>
              <v-expansion-panel-header class="custom-card-bg-1 text-truncate mt-1">
                <div class="text-truncate" style="flex: none; width: 160px; font-size: 14px">
                  Menus
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="background: transparent">
                <v-text-field v-model="menuSearch" label="Search" single-line hide-details></v-text-field>
                <v-list-item
                  v-for="(item, index) in filteredMenus"
                  :key="item.code"
                  draggable="true"
                  @dragstart="dragStart({ children: filteredMenus, type: 'itemList' }, index)"
                  class="pr-0 pl-1 my-1 position-relative custom-card-bg-1"
                  style="cursor:grab;"
                >
                  <v-list-item-content>
                    <v-list-item-title @click="group = item.code" class="text-truncate" style="font-size: 14px">
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
      <v-col cols="10">
        <div style="position: relative;">
          <div class="custom-window" v-if="selectedChild">
            <v-card class="content-window">
              <v-toolbar dark src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg">
                <v-toolbar-title>{{ selectedChild.name }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn class="mt-3 mr-1" dark icon @click="selectedChild = null">
                    <v-icon dark size="35">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

              <div class="grid-container ma-10 " style="min-height:200px">
                <div
                  v-for="(grandChild, index) in selectedChild.children"
                  :key="grandChild.code"
                  draggable="true"
                  @dragstart="dragStart(selectedChild, index)"
                  @dragend="dragEnd"
                  @dragenter="dragEnter(selectedChild, index)"
                  @contextmenu.prevent="deleteItem(selectedChild, index)"
                >
                  <div
                    class=" elevation-3 custom-menu"
                    :style="`background-image: url(${config.backend_pub}${grandChild.image_path});`"
                  >
                    <div class="mt-16">
                      <v-icon v-if="!grandChild.image_path" size="128">{{ grandChild.icon }}</v-icon>
                    </div>
                    <v-layout class="fill-height menu-card custom-card-bg-1"
                      ><span class="ml-2 mr-2 mt-2">{{ grandChild.name }}</span>
                      <div class="description">{{ grandChild.description }}</div>
                    </v-layout>
                  </div>
                </div>
                <div
                  v-if="!(selectedChild.children && selectedChild.children.length)"
                  style="min-height: 50px; border: 1px dashed #ccc;display:flex;justify-content:center;align-items:center"
                  @dragenter="dragEnter(selectedChild, index)"
                >
                  Drop here
                </div>
              </div>
            </v-card>
          </div>
          <div style="overflow-y: auto; " v-for="(item, index) in groupItem" :key="index" @dragend="dragEnd">
            <v-card
              draggable="true"
              class="vertical-nav-menu-items mt-1 overflow-y-auto custom-card-bg-1 draggable-item"
              @dragstart="dragStart({ children: groupItem, type: 'all' }, index)"
              @dragenter="dragEnter({ children: groupItem, type: 'all' }, index, true)"
              @dragend="dragEnd"
            >
              <span :ref="`${item.code}`"></span>
              <v-card-title @contextmenu.prevent="deleteItem({ children: groupItem, type: 'all' }, index)"
                ><span style="font-weight: bolder;font-size: 40px;">{{ item.name }}</span></v-card-title
              >
              <v-card-text class="grid-container" style="min-height:200px">
                <div
                  v-for="(child, index) in item.children"
                  :key="child.code"
                  draggable="true"
                  @dragstart.stop="dragStart(item, index)"
                  @dragenter.stop="dragEnter(item, index)"
                  @dragend="dragEnd"
                  class="draggable-item"
                  @contextmenu.prevent="deleteItem(item, index)"
                >
                  <div
                    class=" elevation-3 custom-menu"
                    :style="`background-image: url(${config.backend_pub}${child.image_path});`"
                  >
                    <v-btn
                      style="position: absolute; right: 0;top: 0;background: linear-gradient(to top, rgb(2, 1, 5), rgba(7, 7, 7, 0.2)) !important;"
                      v-if="child.type == 'group'"
                      @click="selectedChild = child"
                    >
                      Open Group
                      <v-icon>mdi-arrow-top-right</v-icon>
                    </v-btn>
                    <div class="mt-16">
                      <v-icon v-if="!child.image_path" size="128">{{ child.icon }}</v-icon>
                    </div>
                    <v-layout class="fill-height menu-card custom-card-bg-1"
                      ><span class="ml-2 mr-2 mt-2">{{ child.name }}</span>
                      <div class="description">{{ child.description }}</div>
                    </v-layout>
                  </div>
                  <v-spacer></v-spacer>
                </div>
                <div
                  v-if="!(item.children && item.children.length)"
                  style="min-height: 50px; border: 1px dashed #ccc;display:flex;justify-content:center;align-items:center"
                  @dragenter.stop="dragEnter(item, index)"
                >
                  Drop here
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-layout>
</template>
<script>
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import DetailDialog from './detailDialog.vue'
import config from '@/config'
import { getDomains, getGroups, getMenus, updateMenus, getSettingMenus, updateSettingMenus } from '@/api/menu-api'
import menu from '@/store/modules/menu'
export default {
  name: 'GroupView',
  components: { DetailDialog },
  data() {
    return {
      config: config,
      groupItem: [],
      currentGroup: null,
      dragging: null,
      miniDrawer: false,
      Domains: [],
      Groups: [],
      Menus: [],
      mini: false,
      originMenu: [],
      selectedChild: null,
      loading: false,
      groupSearch: '',
      menuSearch: '',
      domainSearch: '',
    }
  },
  props: {},
  computed: {
    ...mapState('menu', ['menu']),
    ...mapState('auth', ['currentUser']),
    ...mapState('project', ['projects']),
    filteredDomains() {
      if (!this.domainSearch) {
        return this.Domains
      }
      return this.Domains.filter(domain => domain.name.toLowerCase().includes(this.domainSearch.toLowerCase()))
    },
    filteredGroups() {
      if (!this.groupSearch) {
        return this.Groups
      }
      return this.Groups.filter(group => group.name.toLowerCase().includes(this.groupSearch.toLowerCase()))
    },
    filteredMenus() {
      if (!this.menuSearch) {
        return this.Menus
      }
      return this.Menus.filter(menu => menu.name.toLowerCase().includes(this.menuSearch.toLowerCase()))
    },
    group: {
      get() {
        if (!this.$route.query.group) this.$router.push({ query: { group: 'home' } })
        return this.$route.query.group.split('/')[0] || ''
      },
      set(newValue) {
        this.$router.push({ query: { ...this.$route.query, group: newValue } })
      },
    },
  },
  watch: {
    menu(val) {
      this.groupItem = this.findGroupItem(this.group)
      this.originMenu = JSON.parse(JSON.stringify(this.groupItem))
    },
    group(val) {
      this.groupItem = this.findGroupItem(val)
      this.$nextTick(() => {
        const cardRef = this.$refs[`${this.$route.query.group.split('/')[1] || ''}`]
        if (!cardRef) return
        cardRef[0].scrollIntoView({ behavior: 'smooth' })
      })
    },
  },
  mounted() {
    this.getDomains()
    this.getGroups()
    this.getMenus()
    this.groupItem = this.findGroupItem(this.group)
    this.originMenu = JSON.parse(JSON.stringify(this.groupItem))
    this.$nextTick(() => {
      const cardRef = this.$refs[`${this.$route.query.group.split('/')[1] || ''}`]
      if (!cardRef) return
      cardRef[0].scrollIntoView({ behavior: 'smooth' })
    })
  },
  methods: {
    undo() {
      this.groupItem = JSON.parse(JSON.stringify(this.originMenu))
    },
    recursiveMap(items) {
      return items.map(item => {
        let result = {
          type: item.type,
          id: null,
          menus: [],
          groups: [],
        }
        if (item.type === 'domain') {
          let domain = this.Domains.find(domain => domain.code === item.code)
          if (domain) result.id = domain.id
        } else if (item.type === 'group') {
          let group = this.Groups.find(group => group.code === item.code)
          if (group) result.id = group.id
        } else if (item.type === 'menu') {
          let menu = this.Menus.find(menu => menu.code === item.code)
          if (menu) result.id = menu.id
        }
        if (item.children) {
          item.children.forEach(child => {
            if (child.type === 'menu') {
              let menu = this.Menus.find(menu => menu.code === child.code)
              if (menu) result.menus.push({ id: menu.id, type: 'menu' })
            } else if (child.type === 'group') {
              result.groups.push(this.recursiveMap([child])[0])
            }
          })
        }

        return result
      })
    },
    closeWindow() {
      this.selectedChild = null
    },
    async updateMenus() {
      let menus = this.recursiveMap(this.groupItem.filter(item => item.code !== 'home'))
      this.loading = true
      await updateSettingMenus(1, { value: 'array:' + JSON.stringify(menus) })
      this.loading = false
    },
    getDomains() {
      getDomains({ per_page: 'all' }).then(res => {
        this.Domains = res.data
      })
    },
    getGroups() {
      getGroups({ per_page: 'all' }).then(res => {
        this.Groups = res.data
      })
    },
    getMenus() {
      getMenus({ per_page: 'all' }).then(res => {
        this.Menus = res.data
      })
    },
    async changeStatus() {
      if (!this.mini) {
        this.mini = !this.mini
        await sleep(300)
        this.miniDrawer = this.mini
      } else {
        this.miniDrawer = !this.miniDrawer
        await sleep(300)
        this.mini = this.miniDrawer
      }
    },
    deleteItem(item, index) {
      item.children.splice(index, 1)
    },
    findInChildren(groupItems, code) {
      for (let i = 0; i < groupItems.length; i++) {
        if (groupItems[i].code === code) {
          return groupItems[i]
        }
        if (groupItems[i].children) {
          let found = this.findInChildren(groupItems[i].children, code)
          if (found) return found
        }
      }
      return null
    },
    dragStart(item, index, isGroup = false) {
      if (isGroup && (item.children[index].type === 'group' || item.children[index].type === 'domain')) {
        let groupItem = this.findInChildren(this.groupItem, item.children[index].code)
        if (groupItem) {
          item.children[index].children = groupItem.children
        } else {
          item.children[index].children = []
          this.groupItem = [...this.groupItem]
        }
      }
      this.dragging = {
        index: index,
        item: item,
        isGroup: isGroup,
      }
    },
    dragEnter(item, index, isDomain = false) {
      const draggingItem = this.dragging?.item.children[this.dragging.index]
      if (!draggingItem) return
      const isDraggingDomain = draggingItem.type === 'domain'
      const isTargetAll = item.type === 'all'
      if ((isDraggingDomain && !isTargetAll) || (!isDraggingDomain && isTargetAll)) {
        return
      }
      if (this.dragging.item.type !== 'itemList') {
        this.dragging.item.children.splice(this.dragging.index, 1)
      }
      if (!isDomain) this.groupItem = [...this.groupItem]
      if (item.children && !item.children.find(child => child.code === draggingItem.code)) {
        item.children.splice(index, 0, draggingItem)
      }
      this.dragging = { index, item, isGroup: this.dragging.isGroup }
      if (this.dragging.isGroup) {
        this.groupItem = [...this.groupItem]
      }
    },
    dragEnd() {
      this.dragging = null
    },
    findGroupItem(group) {
      if (group === 'home') return this.menu.filter(item => item.name !== 'Home')
      if (!group) return this.menu
      let menu = this.menu.find(menu => menu.code === group).children
      return menu
    },
  },
}
</script>

<style scoped>
.draggable-item {
  transition: transform 0.5s;
  cursor: grab;
}
.custom-menu {
  border-radius: 10px;
  min-width: 220px;
  border-radius: 16px;
  position: relative;
  height: 350px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  position: relative;
  align-items: center;
}
.custom-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
}
.menu-card {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: fit-content;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(to top, rgb(2, 1, 5), rgba(7, 7, 7, 0.2)) !important;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  gap: 20px;
}
.license-icon {
  margin: 15px;
  background-color: rgba(39, 44, 78, 0.5) !important;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 15px;
}
.license-icon :hover {
  filter: brightness(1.5);
}
/deep/.license-icon .theme--dark.v-icon {
  color: rgb(255, 219, 61) !important;
}
.description {
  font-size: 17px;
  font-weight: normal;
  color: rgb(206, 202, 221);
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: left;
}
.menus {
  position: sticky;
  right: 20px;
  top: 20px;
  width: 50px;
  z-index: 100;
}
.custom-window {
  position: fixed;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  z-index: 100;
  justify-content: center;
  background-color: rgba(7, 7, 7, 0.7);
  display: flex;
  flex-direction: column;
}
.content-window {
  width: 83%;
}
</style>
