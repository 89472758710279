<template>
  <v-dialog v-model="showDialog"  transition="dialog-bottom-transition">
  <v-card style="width: 100%; height: 100%;">
    <div style="height: 500px;width: 500px; background-color: aquamarine;"></div>
  </v-card> </v-dialog>
</template>

<script>
export default {
  data: () => ({
    showDialog: true,
    order: {},
    pipelineTask: [],
  }),
  computed: {},
  watch: {
    showDialog() {},
  },
  methods: {
    showDetail(order) {
      this.showDialog = true
      this.order = order
      this.getPipelineTask()
    },
  },
}
</script>

<style>
.label-order {
  width: 90px;
  color: #d0d3d4;
}
.pipline-content {
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}
.footer {
  position: sticky;
  bottom: 0;
  background-color: #333649;
}
</style>
